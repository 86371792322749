<template>
  <div class="ruleDescriptionBox">

    <headSearch ref="headSearch" :search-out="headSearch" />

    <div class="ruleDescriptionContent">
      <h3>什么是成长值?</h3>
      <div>
        <p>1、成长值用于悦淘平台消费者的《用户忠诚计划》的计算单位;</p>
        <p>
          2、成长值的获得是根据用户在平台上消费的品类、金额及次数等多维度计算得出。(类似航空公司的积分,如航空公司的积分是通过购票金额和飞行里程得来的);
        </p>
        <p>
          3、成长值累计越高,享受的权益及用户级别越高。(如航空公司的普通会员卡、金卡、白金卡、终身白金卡,可以享受不同特权和待遇,如贵宾厅、免费餐、专车接送等;如银行的各种类型的卡不同的待遇)。
        </p>
      </div>
      <h3>为什么会有预估成长值?</h3>
      <div>
        <p>在正常情况下,商品的成长值是在下单后给出;</p>
        <p>
          本着给大家提供良好的购物体验的原则,平台在购买商品前优先提供了商品的预估成长值,作为买家下单参考依据;
        </p>
      </div>

      <h3>成长值到账时间?</h3>
      <div>
        <p>
          成长值会在买家下单并实际支付成功后事实到账(发生退款后成长值会被扣除)。
        </p>
      </div>

      <h3>成长值特别说明?</h3>
      <div>
        <p>
          所有商品享受优惠后,成长值会相应减少,优惠包含(立减活动、优惠券及其他相应优惠活动)
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import headSearch from '@/component/head/search'

export default {
  name: 'RuleDescription',
  components: {
    headSearch
  },
  data() {
    return {
      headSearch: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        searchWidth: '94%',
        bgColor: '#fff', // 背景颜色
        titleData: '预估成长值',
        callbackGo: this.callbackGo // 回调
      }
    }
  },
  methods: {
    // 上一页
    callbackGo() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.ruleDescriptionBox {
  width: 100%;

  .ruleDescriptionContent {
    padding: 10px;
    width: 100%;
    text-align: left;
    line-height: 22px;

    h3 {
      font-weight: bold;
      padding: 10px 0;
      border-bottom: 1px solid #ccc;
      margin-bottom: 10px;
    }
  }
}
</style>
